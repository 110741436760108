<template>
  <div>
    <div style="padding: 20px; height: 100%">
      <div class="df aic jcb" style="width: 100%">
        <div style="flex: 1">
          <el-form :inline="true" class="demo-form-inline">
            <div class="df aic">
              <el-form-item size="medium" class="mr10" style="flex: 0 0 16%">
                <el-input
                  placeholder="关键词"
                  v-model="query.keyword"
                  clearable
                  class="input-with-select"
                  @clear="onSubmit"
                >
                </el-input>
              </el-form-item>
              <el-button
                type="success"
                size="medium"
                @click="onSubmit"
                class="mr10"
                style="
                  height: 36px;
                  border: none !important;
                  margin-bottom: 20px;
                "
                >查询</el-button
              >
              <el-popover placement="bottom" :width="500" v-model="showAdd">
                <div style="padding: 10px 10px 0">
                  <el-form
                    ref="form"
                    :model="data"
                    label-width="80px"
                    :rules="rules"
                  >
                    <el-form-item label="模板名称" prop="name">
                      <div style="width: 80%">
                        <el-input v-model="formData.name"></el-input>
                      </div>
                    </el-form-item>
                    <el-form-item label="合同模板" prop="url">
                      <div style="width: 80%">
                        <el-upload
                          class="upload-demo"
                          action="#"
                          :http-request="
                            (params) => {
                              addFileData(params, 0);
                            }
                          "
                          :file-list="fileList"
                          :on-remove="() => removeFile(0)"
                        >
                          <el-button size="small" type="primary"
                            >点击上传</el-button
                          >
                        </el-upload>
                      </div>
                    </el-form-item>
                  </el-form>
                </div>
                <div style="text-align: right; margin: 0; padding: 0 10px 10px">
                  <el-button size="mini" type="text" @click="cancelAdd"
                    >取消</el-button
                  >
                  <el-button type="primary" size="mini" @click="handelAdd"
                    >确定</el-button
                  >
                </div>

                <el-button
                  type="primary"
                  size="medium"
                  class="mr10"
                  slot="reference"
                  style="
                    height: 36px;
                    border: none !important;
                    margin-bottom: 20px;
                  "
                  >新增</el-button
                >
              </el-popover>
            </div>
          </el-form>
        </div>

        <el-button
          type="primary"
          size="medium"
          class="mr10"
          @click="$router.push('/admin/contract-list')"
          style="height: 36px; border: none !important; margin-bottom: 20px"
          >历史合同</el-button
        >
      </div>

      <el-table :data="list" style="width: 100%">
        <el-table-column
          prop="name"
          label="模板名称"
          width="300"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="createdTime"
          label="创建时间"
          width="300"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.createdTime.replace("T", " ") }}
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" width="300" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="disib"
              size="mini"
              style="margin: 0 20px"
              @click="handleDetail(scope.row)"
              >详情</el-button
            >
            <el-popover
              placement="bottom"
              :width="500"
              v-model="scope.row.showEdit"
              trigger="manual"
            >
              <div style="padding: 10px 10px 0">
                <el-form
                  ref="form1"
                  :model="editData"
                  label-width="80px"
                  :rules="rules"
                >
                  <el-form-item label="模板名称" prop="name">
                    <div style="width: 80%">
                      <el-input v-model="editForm.name"></el-input>
                    </div>
                  </el-form-item>
                  <el-form-item label="合同模板" prop="url">
                    <div style="width: 80%">
                      <el-upload
                        class="upload-demo"
                        action="#"
                        :http-request="
                          (params) => {
                            addFileData(params, 1);
                          }
                        "
                        :file-list="editForm.url"
                        :on-remove="() => removeFile(1)"
                      >
                        <el-button size="small" type="primary"
                          >点击上传</el-button
                        >
                      </el-upload>
                    </div>
                  </el-form-item>
                </el-form>
              </div>
              <div style="text-align: right; margin: 0; padding: 0 10px 10px">
                <el-button
                  size="mini"
                  type="text"
                  @click="cancelEdit(scope.row)"
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  @click="handelEdit(scope.row)"
                  >确定</el-button
                >
              </div>
              <el-button
                style="margin: 0 20px"
                type="text"
                class="disib"
                size="mini"
                slot="reference"
                @click="
                  editForm.name = scope.row.name;
                  editForm.url = [JSON.parse(scope.row.url)];
                  editForm.id = scope.row.id;
                  scope.row.showEdit = true;
                "
                >修改</el-button
              >
            </el-popover>

            <el-button
              style="margin: 0 20px"
              type="text"
              class="disib red"
              size="mini"
              @click="delItem(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="df" style="justify-content: flex-end; padding: 20px 0">
        <el-pagination
          background
          :page-size="query.limit"
          layout="total,prev, pager, next"
          :total="total"
          :current-page="query.page"
          @current-change="pageChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/util/api";
import { local } from "@/util/util";
import api from "@/util/extra-api";

export default {
  data() {
    return {
      query: {
        keyword: "",
        page: 1,
        limit: 10,
      },
      list: [],
      companyId: null,
      showAdd: false,
      // showEdit: false,
      formData: {
        name: "",
      },
      fileList: [],
      total: 0,
      rules: {
        name: [{ required: true, message: "请输入模板名称", trigger: "blur" }],
        url: [{ required: true, message: "请上传合同模板", trigger: "blur" }],
      },
      editForm: {
        name: "",
        url: [],
        id: null,
      },
    };
  },
  mounted() {
    this.companyId = Number(local.get("company_id"));
    this.getList();
  },
  computed: {
    data() {
      return {
        name: this.formData.name,
        url: this.fileList.length ? JSON.stringify(this.fileList[0]) : "",
        companyId: this.companyId,
      };
    },
    editData() {
      return {
        name: this.editForm.name,
        url: this.editForm.url.length
          ? JSON.stringify(this.editForm.url[0])
          : "",
        id: this.editForm.id,
        companyId: this.companyId,
      };
    },
  },
  methods: {
    handelAdd() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          api
            .post(`/v1/wx/contract/add?companyId=${this.companyId}`, this.data)
            .then((res) => {
              if (res.code == 200) {
                this.showAdd = false;
                this.fileList = [];
                this.formData.name = "";
                this.getList();
              }
            });
          // console.log(this.data);
        } else {
          return false;
        }
      });
    },
    handelEdit(row) {
      this.$refs["form1"].validate((valid) => {
        if (valid) {
          api
            .post(
              `/v1/wx/contract/update?companyId=${this.companyId}`,
              this.editData
            )
            .then((res) => {
              if (res.code == 200) {
                // row.showEdit = false;
                this.$set(row, "showEdit", false);
                this.editForm.name = "";
                this.editForm.url = [];
                this.editForm.id = null;
                this.getList();
              }
            });
          // console.log(this.data);
        } else {
          return false;
        }
      });
    },
    cancelAdd() {
      this.showAdd = false;
      this.formData.name = "";
      this.fileList = [];
      this.$refs["form"].clearValidate();
    },
    cancelEdit(row) {
      this.$set(row, "showEdit", false);
      // this.showEdit = false;
      this.editForm.name = "";
      this.editForm.url = [];
      this.editForm.id = null;
      this.$refs["form1"].clearValidate();
    },
    onSubmit() {
      this.query.page = 1;
      this.getList();
    },
    handleDetail(row) {
      // this.
      // console.log(
      //   {
      //     name: row.name,
      //     url: row.url,
      //     replaceContent: row.replaceContent,
      //     id: row.id,
      //     companyId: this.companyId,
      //   },
      //   123
      // );

      this.$router.push({
        name: "contract-preview",
        query: {
          url: row.url,
          replaceContent: row.replaceContent,
          id: row.id,
          companyId: this.companyId,
        },
      });
    },
    delItem(row) {
      api
        .post(`/v1/wx/contract/delete`, {
          id: row.id,
          companyId: this.companyId,
        })
        .then((res) => {
          if (res.code == 200) {
            if (this.list.length == 1 && this.query.page > 1) {
              this.query.page--;
            }
            this.getList();
          }
        });
    },
    removeFile(type) {
      if (!type) {
        this.fileList = [];
      } else {
        this.editForm.url = [];
      }
    },
    addFileData(params, type) {
      const file = params.file,
        isLt10M = file.size / 1024 / 1024 < 10;
      // pdf、word、excel
      if (
        file.name.split(".").pop() !== "doc" &&
        file.name.split(".").pop() !== "docx"
        // file.name.lastIndexOf(".pdf") != -1
      ) {
        console.log(file.name.split(".").pop() !== "doc");
        console.log(file.name.split(".").pop() !== "docx");
        console.log(
          file.name.split(".").pop() !== ".doc" &&
            file.name.split(".").pop() !== ".docx",
          123123
        );
        this.$message.error("只能上传word文件");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("只能上传文件大小小于10M");
        return false;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", this.companyId);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("文件上传失败，请重新上传");
        } else {
          if (!type) {
            // console.log(resp.data);
            this.fileList = [
              {
                name: resp.name,
                url: resp.url,
              },
            ];
            this.$refs["form"].clearValidate(["url"]);
          } else {
            this.editForm.url = [
              {
                name: resp.name,
                url: resp.url,
              },
            ];
          }
        }
      });
    },
    getList() {
      api
        .get(`/v1/wx/contract/pc/list?companyId=${this.companyId}`, this.query)
        .then((res) => {
          if (res.code === 200) {
            this.$set(
              this,
              "list",
              res.data.map((v) => {
                return {
                  ...v,
                  showEdit: false,
                };
              })
            );
            this.total = res.total;
          }
        });
    },
    pageChange(e) {
      this.query.page = e;
      this.getList();
    },
  },
};
</script>
 
<style scoped lang="scss">
/deep/.el-table__empty-block {
  width: 100% !important;
}
.jcb {
  justify-content: space-between;
}
</style>